import { graphql, StaticQuery, withPrefix } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ImageRow } from "../components/ImageRow";

const Page = () => (
	<StaticQuery
		query={graphql`
			query {
				# image1: file(relativePath: { eq: "IMG_20190302_163132.png" }) {
				image1: file(relativePath: { eq: "G17001--exploded.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				kidsInCar: file(relativePath: { eq: "01-03-19-04442.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		`}
		render={data => <Page1 data={data} />}
	/>
);

const Page1 = ({ data }: { data: any }) => (
	<Layout>
		<SEO title="ABC Magnete, Alphabet Magnete" />
		<TextPadding>
			<h1 style={{ textAlign: "center", marginTop: "3em", marginBottom: "0", padding: "0" }}>
				Galoxee Magnetisches ABC
			</h1>
			<h1 style={{ textAlign: "center", paddingTop: "0.5em" }}>
				Schlaues Spielzeug für pfiffige Kinder.
			</h1>
		</TextPadding>
		<ImageA />
		<TextPadding>
			<ContentCenterWrapper>
				<p style={{ lineHeight: "28px" }}>
					Unser Ziel für Ihre Kinder: Freude am Lernen. Deshalb haben wir ein Set
					entwickelt, mit dem Kinder sogar ganze Sätze schreiben können. Unsere Buchstaben
					halten zuverlässig auf allen magnetischen Untergründen und in jedem Set ist eine
					faltbare, magnetische Unterlage dabei. Erste Buchstabenspiele finden Sie gratis
					auf dieser Webseite. So macht Lesen und Schreiben Spaß!
				</p>
			</ContentCenterWrapper>
		</TextPadding>
		{/* Quantities */}
		<Quantities>
			<div style={{ flexBasis: "200px", flex: 1, marginBottom: "2em" }}>
				<Quantity>119</Quantity>
				<div style={{ fontSize: ".8em", paddingLeft: ".5em" }}>Buchstaben</div>
			</div>
			<div style={{ flexBasis: "200px", flex: 1, marginBottom: "2em" }}>
				<Quantity>A3/A4</Quantity>
				<div style={{ fontSize: ".8em", paddingLeft: ".5em" }}>Tafelgröße (offen/zu)</div>
			</div>
			<div style={{ flexBasis: "200px", flex: 1, marginBottom: "2em" }}>
				<Quantity>5-8 J.</Quantity>
				<div style={{ fontSize: ".8em", paddingLeft: ".5em" }}>Altersgruppe</div>
			</div>
		</Quantities>
		<ImageRow
			imageFluid={data.image1.childImageSharp.fluid}
			height="70%"
			// widths={["100%", "80%", "70%"]}
			widths={["100%", "100em", "100em"]}
		/>
		{/* <div>
			<Img style={{ marginTop: "0" }} />
			<div
				style={{
					paddingTop: ".7rem",
					textAlign: "center",
					fontSize: "90%",
					marginTop: "-2em",
				}}
			>
				ABC-Magnete (links), Tafel (mittig), Geschenk- und Aufbewahrungsbox (rechts)
			</div>
		</div> */}
		<div
			style={{
				paddingTop: ".7rem",
				textAlign: "center",
				fontSize: "90%",
				margin: "-5em auto 0",
			}}
		>
			Inhalt: Schaumstoff-Magnete, magnetische Unterlage, Aufbewahrungsbox
		</div>
		<TextPadding style={{ marginTop: "4rem" }}>
			<ContentCenterWrapper>
				<h1 style={{ paddingTop: 0 }}>
					2 Millionen Wörter,
					<br />
					sehr viele Wortkreationen.
				</h1>
				<p style={{ lineHeight: "28px", paddingBottom: ".7em" }}>
					Nahezu alle deutschen Worte können mit diesem Set gelegt werden (
					<a href="/G17001-Worte.txt" target="_blank">
						fast 2 Millionen!
					</a>
					), und dadurch beinahe unendlich viele kurze Botschaften. Freie Bahn für
					Phantasie und Lernspaß! Erste Buchstaben-Spiele und Wort-Übungen zum kostenlosen
					Download finden Sie hier.
				</p>
			</ContentCenterWrapper>
		</TextPadding>
		<br />
		<ImageC />
		<TextPadding>
			{/* <ContentLeftWiderWrapper>
				<h2>Ordnungshelfer</h2>
				<div>
					119 Buchstaben, alle auf einem Haufen. Wo ist denn nur das "A" geblieben? Für
					eine bequeme Handhabung und leichtes Verstauen im Schrank haben wir diese
					spezielle Box entwickelt. Das Galoxee-Set, in seiner schönen und praktischen
					Verpackung, ist ein beliebtes Geschenk und Mitbringsel. Es ist besonders gut für
					Vorschüler und Schulanfänger geeignet.
				</div>
			</ContentLeftWiderWrapper> */}
		</TextPadding>
		<ContentLeftWiderWrapper>
			<RowTop>
				<div style={{ flex: 1 }}>
					<TextPadding>
						<h3>In besten Händen</h3>
						Keine scharfen Kanten, Splitter oder herausfallenden Magnetteile: Unser
						Material ist sicher und auch abwaschbar. Die Buchstaben haben eine
						Magnetschicht und sind aus robusten Schaumstoff gefertigt. Dieses Spielzeug
						ist frei von Schadstoffen.
					</TextPadding>
				</div>
				<div style={{ flex: 1 }}>
					<TextPadding>
						<h3>Lust statt Frust</h3>
						<div>
							Bei uns fallen einzelne Buchstaben beim Spielen nicht ständig runter.
							Wir haben dafür gesorgt, dass die Magnete gut halten und die Kinder die
							Lust am Üben behalten. Die Magnetschicht ist stark aber für Kinderhände
							immer noch angenehm handlich.
						</div>
					</TextPadding>
				</div>
			</RowTop>
		</ContentLeftWiderWrapper>
		<div style={{ height: "3em" }} />
		<ImageRow
			imageFluid={data.kidsInCar.childImageSharp.fluid}
			height="63%"
			// widths={["100%", "80%", "70%"]}
			widths={["100%", "690px", "690px"]}
		/>
		<ContentLeftWiderWrapper>
			<RowTop>
				<div style={{ flex: 1 }}>
					<TextPadding>
						<h3>Was, wir sind schon da?!</h3>
						<div>
							Reisenzeiten spielerisch lernend überbrücken? Kein Problem: Die faltbare
							Tafel kann im Din A3 oder Din A4 Format genutzt werden und die starken
							Magnete halten zuverlässig darauf. Im Nu sind Sie am Ziel angekommen.
						</div>
					</TextPadding>
				</div>
				<div style={{ flex: 1 }}>
					<TextPadding>
						<div>
							<h3>Galoxee Vorteile auf einen Blick</h3>
							<ul style={{ marginTop: 0 }}>
								<li>Beste Qualität und Sicherheit </li>
								<li>Starke Magnethaftung</li>
								<li>Passende magnetische Unterlage</li>
								<li>Praktische Aufbewahrungs-Box</li>
								<li>Speziell für deutsche Sätze konzipiert </li>
								<li>Tausende Wortkreationen möglich</li>

								<li>Schadstoffgeprüft </li>
								<li>Gute Vorbereitung auf die Schule </li>
								<li>Pädagogisch wertvolles Mitbringsel</li>
							</ul>
						</div>
					</TextPadding>
				</div>
			</RowTop>
		</ContentLeftWiderWrapper>
		<ContentLeftWiderWrapper>
			<TextPadding>
				<b>Vorsicht:</b> Die Magnete sind nicht für Kinder unter 3 Jahre geeignet. Unter
				Aufsicht benutzen.
			</TextPadding>
		</ContentLeftWiderWrapper>
		<br />
	</Layout>
);

const Quantities = styled.div`
	text-align: center;
	@media (min-width: 600px) {
		margin: 0 auto;
		width: 100%;
		max-width: 600px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`;

const Quantity = styled.div`
	font-family: Roboto;
	font-size: 3.5em;
	font-weight: 300;
	color: var(--galoxeeOrange);
`;

const TextPadding = styled.div`
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	@media (min-width: 742px) {
		padding-left: 0;
		padding-right: 0;
	}
`;

/**
 * Cenetered divs have a co
 * Height: 500px (if >= 742), 700px (if >= 920)
 */
const RowWrapper = styled.div`
	width: 100%;
	@media (min-width: 742px) {
		min-height: 480px;
	}
	@media (min-width: 920px) {
		min-height: 700px;
	}
`;

const RowTop = styled.div`
	@media (min-width: 742px) {
		display: flex;
	}
`;

const RowMiddle = styled.div`
	@media (min-width: 742px) {
		display: flex;
		align-items: center;
	}
`;

const RowLeft = styled.div`
	@media (min-width: 742px) {
		width: 50%;
		order: 1;
	}
`;

const RowRight = styled.div`
	@media (min-width: 742px) {
		width: 50%;
		order: 2;
	}
`;

/**
 * 520px for text
 */
const ContentCenterWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: center;
	@media (min-width: 742px) {
		max-width: 520px;
	}
`;

const ContentLeftWiderWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: left;
	@media (min-width: 742px) {
		max-width: 690px;
	}
`;

/** Ensure padding around the content */
const ContentWrapper = styled.div`
	padding: 3em;
`;

const ContentLeft = styled.div`
	color: white;
	margin: 0 auto;
	@media (min-width: 742px) {
		width: 260px;
		margin: 0 2em 0 auto;
	}
	@media (min-width: 920px) {
		width: 360px;
		font-size: 110%;
	}
`;

const ContentRight = styled.div`
	color: white;
	margin: 0 auto;
	@media (min-width: 742px) {
		width: 260px;
		margin: 0 auto 0 2em;
	}
	@media (min-width: 920px) {
		width: 360px;
		font-size: 110%;
	}
`;

const ImageA = styled.div`
	background-image: url("${withPrefix("/images/26-02-19-03933.jpg")}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		max-width: 50em;
		height: 300px;
		padding: 0;
		background-size: cover;
		margin: 0 auto;
	}
`;

const ImageB = styled.div`
	background-image: url("${withPrefix("/images/26-02-19-03945.jpg")}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: contain;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

const ImageC = styled.div`
	background-image: url("${withPrefix("/images/022619_Kids_Room.png")}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: contain;
	}
`;

const Image1 = styled.div`
	background-image: url("https://lorempixel.com/400/400/technics/");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: cover;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

const Image2 = styled.div`
	background-image: url("https://lorempixel.com/400/400/sports/");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: cover;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

const Image3 = styled.div`
	background-image: url("https://lorempixel.com/400/400/people/");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: cover;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

export default Page;
