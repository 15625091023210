import React from "react";
import styled from "styled-components";

const BackgroundImage = require("gatsby-background-image").default;

interface ImageRowProps {
	readonly imageFluid: any;
	readonly style?: React.CSSProperties;
	readonly height: string;
	readonly widths: [string, string, string];
}

export function ImageRow(props: ImageRowProps): JSX.Element {
	const imageSize = "contain";
	// const imagePosition = props.imagePosition || "center";

	const ImageWrapper = styled.div`
		width: ${props.widths[0]};
		margin: 0 auto;
		@media (min-width: 742px) {
			width: ${props.widths[1]};
		}
		@media (min-width: 920px) {
			width: ${props.widths[2]};
		}
	`;

	const StyledBackgroundImage = styled(BackgroundImage)`
		background-size: ${imageSize};
		height: 100%;
		padding-top: ${props.height};
		width: 100%;
		z-index: 0;
		&:before,
		&:after {
			background-size: contain;
		}
	`;

	return (
		<ImageWrapper style={props.style}>
			<StyledBackgroundImage fluid={props.imageFluid} />
		</ImageWrapper>
	);
}
